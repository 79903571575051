import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecurityContactPage = () => (
  <Layout>
    <SEO title="Security contact" keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]} />
      <div>
        <h1>Security contact</h1>
        <div>
          <p>We appreciate every report, however, please take into account that our team is very occupied and thus will not respond to reports that we qualify as low severity or invalid (for examples server DoS or headers”X-Powered-By”). So please, don’t report these kind of things, because we won’t take them into consideration.</p>
          <p>If you discover a security issue with an election, please read our Responsible Disclosure policy and contact us in&nbsp;<a href="mailto:secure@nvotes.com" target="_blank" rel="noopener noreferrer">secure@nvotes.com</a>. Your report must include:</p>
          <ul>
            <li>Vulnerability description</li>
            <li>Reproduction steps</li>
          </ul>
          <p>A member of our security team will confirm the validity of the Vulnerability, determine its impact and develop a fix. This fix will be applied Un miembro de nuestro equipo de seguridad confirmará la validez de la vulnerabilidad, determinará su impacto y desarrollará su arreglo. Este arreglo será aplicado as soon as possible.</p>
          <h4>PGP key to send the report</h4>
          <p>To facilitate the secure reception and sending of security reports, we provide the&nbsp;<a href="https://pgp.mit.edu/pks/lookup?search=0xB7732972775937D8">following PGP key</a>&nbsp;for confidential submission:</p>
          <ul>
            <li>Key ID:&nbsp;<strong>7759 37D8</strong></li>
            <li>Fingerprint:&nbsp;<strong>77BD 5F94 0D8B 7498 B438 8917 B773 2972 7759 37D8</strong></li>
            <li>Expiration date:&nbsp;<strong><code>10/7/21</code></strong></li>
            <li>Email:&nbsp;<strong><a href="mailto:secure@nvotes.com" target="_blank" rel="noopener noreferrer">secure@nvotes.com</a></strong></li>
          </ul>
          <h6>Make sure to not disclose details in the subject, as it will not be encrypted!</h6>
          <h4>Responsible Disclosure policy</h4>
          <p>The Agora Voting Security Team asks that you comply with the following guidelines when researching and reporting security vulnerabilities:</p>
          <ul>
            <li>Only test for vulnerabilities on your own install of Agora Voting</li>
            <li>Never try to compromise a production ongoing election</li>
            <li>Confirm that the Vulnerability applies to the version installed in production</li>
            <li>hare vulnerabilities in detail and only with the security team, with an encrypted email</li>
            <li>Allow reasonable time for a response from the security team</li>
            <li>Do not publish information related to the vulnerability until Agora Voting has made an announcement to the community</li>
          </ul>
          <p>security vulnerabilities in 3rd party applications or libraries used by Agora Voting should also be reported to the security team. The security team is not responsible for the security of these apps, but will attempt to contact the 3rd party app maintainer and then take proper actions.</p>
        </div>
      </div>

  </Layout>
)

export default SecurityContactPage
